import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAction } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VListItem,[_c(VListItemContent,[_c(VListItemTitle,{staticClass:"d-flex flex-gap-8"},[_c('span',[_vm._v(_vm._s(_vm.$t('Reference'))+":")]),_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(_vm.document.reference_type.replace('_', ' ')))]),_c('span',[_vm._v("#"+_vm._s(_vm.document.reference_number))])]),_c(VListItemSubtitle,{staticClass:"d-flex flex-column flex-gap-8 mt-4"},[_c('div',{staticClass:"d-flex flex-gap-4"},[_c('span',[_vm._v(_vm._s(_vm.$t('Status'))+":")]),_c('span',{class:_vm.statusClass},[_vm._v(_vm._s(_vm.document.status))])]),(_vm.document.status === 'success')?_c('div',{staticClass:"d-flex flex-gap-4"},[_c('span',[_vm._v(_vm._s(_vm.$t('iCount number'))+":")]),_c('span',{staticClass:"font-weight-bold"},[_vm._v(_vm._s(_vm.document.docnum))])]):_vm._e(),(_vm.document.status === 'error')?_c('span',{class:_vm.statusClass},[_vm._v(_vm._s(_vm.$t(_vm.document.message)))]):_vm._e()])],1),_c(VListItemAction,[(_vm.document.status === 'error')?_c(VBtn,{attrs:{"disabled":_vm.loading,"loading":_vm.loading,"icon":"","color":"secondary"},on:{"click":_vm.refetchHandler}},[_c(VIcon,[_vm._v("autorenew")])],1):_c('button-action',{attrs:{"icon-name":"print","icon":"","color":"primary","label":_vm.$t('Print')},on:{"click":_vm.printDocument}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }