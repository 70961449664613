import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c(VDataTable,{attrs:{"items":_vm.value.documents,"headers":_vm.headers,"hide-default-footer":""},scopedSlots:_vm._u([{key:"item.type",fn:function(ref){
var item = ref.item;
return [_c('span',{staticClass:"text-capitalize"},[_vm._v(_vm._s(item.type.replace('_', ' ')))])]}},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_c('span',[_vm._v(_vm._s(_vm.$format.timezone.getFormattedTime(item.created_at)))])]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('order-document-actions',{attrs:{"value":item,"order-id":_vm.value.id}})]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }